import _ from "lodash";
import { Conversation, ConversationId, Message, Org, Team } from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { BatchTask } from "@ollie-sports/firebase";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import { compute } from "../..";
import { notification__server__triggerForMessage } from "../notification";

export async function message__server__create(p: { conversationId: ConversationId; selfAccountId: string; messages: Message[] }) {
  const { appOllieFirestoreV2: h, getAppPgPool } = getServerHelpers();

  if (!p.messages.length) {
    throw new Error("Must pass at least one message to create!");
  }

  const { conversation, org, team } =
    (await getAppPgPool()
      .query(
        `SELECT c.item as conversation, t.item as team, o.item as org
FROM mirror_conversation c
LEFT JOIN mirror_team t ON c.item ->> 'teamId' = t.id
LEFT JOIN mirror_org o ON o.id = COALESCE(t.item ->> 'orgId', c.item ->> 'orgId')
WHERE c.id = $1`,
        [p.conversationId]
      )
      .then(a => a.rows[0] as undefined | { conversation: Conversation; team?: Team; org?: Org; message?: Message })) || {};

  if (!conversation) {
    console.error("Unable to find conversation to add message to!", p);
    throw new Error("Unable to find conversation to add message to!");
  }

  if (!compute.conversation.canWriteMessage({ accountId: p.selfAccountId, conversation, org, team })) {
    throw new Error(`User ${p.selfAccountId} does not have permission to write to conversation ${p.conversationId}!`);
  }

  const now = Date.now();
  p.messages = p.messages.map((a, i) => {
    const { createdAtMS, ...rest } = a;
    delete (a as any).clientState; //In case anything has sneaked in from the front end
    return {
      ...rest,
      createdAtMS: now + i //Overwrite their client timestamp with server timestamps
    };
  });

  const thisLastMessage = p.messages.slice(-1).pop()!;

  const tasks = [
    await h.Conversation.update(
      { id: p.conversationId, doc: { lastMessageId: thisLastMessage.id, updatedAtMS: thisLastMessage.createdAtMS } },
      { returnBatchTask: true }
    ),
    ...(await Promise.all(
      p.messages.map(doc => {
        return h.Message.set({ doc, id: doc.id }, { returnBatchTask: true });
      })
    ))
  ].filter(Boolean) as BatchTask[];

  await h._BatchRunner.executeBatch(tasks);

  //Wrap triggering the notification in a timeout because it can happen in the background
  setTimeout(async () => {
    await notification__server__triggerForMessage({
      messageId: thisLastMessage.id,
      selfAccountId: p.selfAccountId
    });
  }, 0);
}

message__server__create.auth = async (req: any) => {
  await validateTokenAndEnsureSelfAccountIdMatches(req);
};

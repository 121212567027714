import _ from "lodash";
import * as express from "express";

import { validateToken } from "../../internal-utils/server-auth";
import { getUniversalHelpers } from "../../helpers";
import { Account, AccountId, AutomatedMessageDetails, ConversationId } from "@ollie-sports/models";
import { combineArrayWithCommasAndAnd } from "../../utils";

export async function message__server__addAutomatedMessage(
  p: { conversationId: ConversationId; accountId: AccountId } & (
    | { type: "addPerson"; addedAccountIds: AccountId[] }
    | { type: "leave" }
  )
) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const account = await h.Account.getDoc(p.accountId);
  let addedAccounts: Account[] = [];
  if (p.type === "addPerson") {
    addedAccounts = _.compact(
      await Promise.all(
        p.addedAccountIds.map(id => {
          return h.Account.getDoc(id);
        })
      )
    );
  }

  let automatedMessageDetails: AutomatedMessageDetails | null = null;

  if (p.type === "leave") {
    if (account) {
      automatedMessageDetails = {
        type: "leftConversation",
        accountName: `${account.firstName} ${account.lastName[0]}.`
      };
    } else {
      return;
    }
  } else {
    if (account && addedAccounts.length) {
      automatedMessageDetails = {
        type: "addedPeople",
        accountName: `${account.firstName} ${account.lastName[0]}.`,
        newAccountNames: addedAccounts.reduce((acc, val) => {
          acc[`${val.firstName} ${val.lastName[0]}`] = true;
          return acc;
        }, {} as Record<string, true>)
      };
    } else {
      return;
    }
  }

  const nowMS = Date.now();

  const newMessageId = h.Message.generateId();

  await h._BatchRunner.executeBatch([
    await h.Message.add(
      {
        doc: {
          accountId: p.accountId,
          conversationId: p.conversationId,
          createdAtServerTimestamp: h._MagicServerTimestamp,
          createdAtMS: nowMS,
          id: newMessageId,
          automatedMessageDetails: automatedMessageDetails,
          deletedAtMS: 0
        }
      },
      { returnBatchTask: true }
    ),
    await h.Conversation.update(
      { id: p.conversationId, doc: { updatedAtMS: nowMS, lastMessageId: newMessageId } },
      { returnBatchTask: true }
    )
  ]);

  // SERVER_ONLY_TOGGLE
}

message__server__addAutomatedMessage.auth = async (r: express.Request) => {
  await validateToken(r);
  // make sure valid token
  // make sure selfAccountId matches request.body.selfAccountId
};

// i18n certified - complete
